import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} article />
            <Header />
            <BackgroundImage className="layout title__48 banner__background" fluid={data.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb breadcrumb__top">
                            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <Link to="/blog/">Blog</Link> &nbsp;&rsaquo;&nbsp; <strong>{ entry.frontmatter.heading }</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="body flex flex__space-around">
                            <div className="block__60 text">
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <h6 className="date">{entry.frontmatter.date}</h6>
                                <Link to="/contact/" className="btn btn__blue btn__small btn__margin__small">Make enquiry &rsaquo;</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        background: file(relativePath: { eq: "shutterstock_778265479.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
                meta_description
                date(formatString: "MM/DD/YYYY")
                heading
			}
			fields {
				slug
			}
			html
		}
	}
`